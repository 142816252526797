/**
 * @param {string} query registration full or partial registration
 * @returns {Array<Object>} matching aircraft
 */
export default async function lookupAircraft(query) {
  if (!query) return [];
  const cleanQuery = query.trim().toLowerCase();

  // console.log(`fetching matching aircraft using: "${cleanQuery}"`);
  const aircraft = [];

  try {
    const response = await fetch(
      `https://v1.toaviate.com/api/v1/airfieldhub/aircraft/${cleanQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "eW91a25vd25vdGhpbmdqb25zbm93",
          "Authorization": "Basic aGVyZWJlOmRyYWdvbnM=",
        },
      }
    );

    const responseData = await response.json();

    let apiAircraft = responseData.aircraft || [];

    apiAircraft.forEach(function (item) {
      if (!item || !item.registration) return;
      aircraft.push(item);
    });
  } catch (e) {
    console.error(e);
  }

  return aircraft;
}