<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="60%"
    @click:outside="onClose"
  >
    <v-card>
      <v-card-title class="text-h6">
        Terms &amp; Conditions
      </v-card-title>
      <v-card-text>
        TODO...
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onClose"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TermsDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      required: true
    }
  }
};
</script>
