/**
 * @param {string} query airfield name or code
 * @returns {Array<Object>} matching airfields
 */
export default async function lookupAirfields(query) {
  if (!query) return [];
  const cleanQuery = query.trim().toLowerCase();

  // console.log(`fetching matching airfields using: "${cleanQuery}"`);
  const airfields = [];

  try {
    const response = await fetch(
      `https://v1.toaviate.com/api/v1/airfieldhub/airfield/${cleanQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "eW91a25vd25vdGhpbmdqb25zbm93",
          "Authorization": "Basic aGVyZWJlOmRyYWdvbnM=",
        },
      }
    );

    const responseData = await response.json();

    let apiAirfields = responseData.airfields || [];

    apiAirfields.forEach(function (item) {
      if (!item || !item.title) return;
      airfields.push(item);
    });
  } catch (e) {
    console.error(e);
  }

  return airfields;
}